<!--充值套餐设置-->
<template>
  <div id="packageSetting">
    <div class="table-search">
      <div class="search-L">
        <el-input
          v-model="tableParams.kw"
          placeholder="输入套餐名称搜索"
          @input="kwChange"
          clearable=""
          class="mr20"
        />
        <el-select
          v-model="tableParams.merchantId"
          placeholder="请选择商户"
          @change="onMerchantSel"
          clearable
          class="mr20"
        >
          <el-option
            v-for="item in commercialList"
            :key="item.merchantId"
            :label="item.merchantName"
            :value="item.merchantId"
          >
          </el-option>
        </el-select>
        <el-select
          v-model="tableParams.shopId"
          placeholder="请选择店铺"
          clearable
          @change="onShopSel"
        >
          <el-option
            v-for="item in tableShopList"
            :key="item.shopId"
            :label="item.shopName"
            :value="item.shopId"
          >
          </el-option>
        </el-select>
      </div>

      <el-button type="primary" @click="goClick(1)">新增充值套餐</el-button>
    </div>
    <!-- 表格 -->
    <el-table
      :data="tableData"
      border
      stripe
      :header-cell-style="{
        background: '#FAFAFA',
        color: '#000000',
      }"
    >
      <el-table-column label="套餐名称" align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{ scope.row.packageName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="充值金额" align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{ scope.row.packageAmount }}</span>
        </template>
      </el-table-column>
      <el-table-column label="赠送金额" align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{ scope.row.giveAmount }}</span>
        </template>
      </el-table-column>
      <el-table-column label="其他赠送" align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{ scope.row.remarks || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="创建时间" align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{ scope.row.addDt }}</span>
        </template>
      </el-table-column>
      <el-table-column label="套餐状态" align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{ scope.row.packageStatus == 0 ? "已下架" : "售卖中" }}</span>
        </template>
      </el-table-column>
      <el-table-column width="200" label="操作" align="center">
        <template slot-scope="scope">
          <el-button
            v-if="scope.row.packageStatus == 0"
            type="text"
            size="medium"
            @click.stop="goClick(2, scope.row)"
            >上架</el-button
          >
          <el-button
            v-else
            type="text"
            size="medium"
            @click.stop="goClick(3, scope.row)"
            >下架</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="table-footer">
      <el-pagination
        layout="total,sizes, prev, pager, next,jumper"
        background
        :total="total"
        :page-size="tableParams.pageSize"
        @size-change="onSizeChange"
        :page-sizes="[10, 20, 50, 100]"
        :current-page.sync="currentPage"
        @current-change="onCurrentPage"
      >
      </el-pagination>
    </div>
    <!--新增充值套餐弹框-->
    <el-dialog
      :visible.sync="addPackageShow"
      title="新增充值套餐"
      :close-on-click-modal="false"
      width="30%"
      custom-class="add-dialog"
    >
      <el-form :model="params" :rules="rules" ref="form-ref">
        <el-form-item label="已选商户" label-width="120px">
          {{ tableParams.merchantName }}</el-form-item
        >
        <el-form-item label="已选门店" label-width="120px"
          >{{ tableParams.shopName }}
        </el-form-item>
        <!-- 可提现金额 -->
        <el-form-item label="套餐名称" label-width="120px" prop="packageName">
          <el-input
            v-model.trim="params.packageName"
            autocomplete="off"
            placeholder="请输入套餐名称"
          ></el-input>
        </el-form-item>
        <!-- 充值金额 -->
        <el-form-item label="充值金额" label-width="120px" prop="rechargeMoney">
          <el-input
            v-model.trim="params.rechargeMoney"
            autocomplete="off"
            type="number"
            placeholder="请输入充值金额"
          ></el-input
        ></el-form-item>
        <!-- 赠送金额 -->
        <el-form-item label="赠送金额" label-width="120px" prop="giftMoney">
          <el-input
            v-model.trim="params.giftMoney"
            autocomplete="off"
            type="number"
            placeholder="请输入赠送金额"
          
          ></el-input>
        </el-form-item>
        <!-- 其他赠送 -->
        <el-form-item label="其他赠送" label-width="120px">
          <el-input
            v-model.trim="params.remarks"
            autocomplete="off"
            maxlength="7"
            placeholder="请输入其他赠送内容"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <div>
          <el-button @click="addPackageShow = false">取 消</el-button>
          <el-button type="primary" @click="onSubmit('form-ref')"
            >保存</el-button
          >
        </div>
        <div class="tips">tips：创建后不支持编辑，请谨慎填写</div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { rules } from "@/db/rules";
import { getAutoCommercialList } from "@/api/commercial/commercial";
import { getIdShopList } from "@/api/commercial/shop";
import {
  getPackageList,
  goAddPackage,
  goUpdatePackage,
} from "@/api/recharge/packageSetting.js";
export default {
  name: "PackageSetting",
  data() {
    return {
      tableData: [],
      rules,
      commercialList: [], // 商户列表
      tableShopList: [], //店铺列表
      tableParams: {
        kw: "",
        page: 1,
        pageSize: 10,
        merchantId: "",
        merchantName: "",
        shopId: "",
        shopName: "",
      },
      total: 0,
      currentPage: 1,
      addPackageShow: false,
      params: {
        packageName: "",
        rechargeMoney: "",
        giftMoney: "",
        remarks: "",
      },
    };
  },
  mounted() {
    this.getAutoCommercialList(); // 【请求】商户下拉列表
  },
  methods: {
    // 套餐列表
    getPackageList() {
      let data = this.tableParams;
      getPackageList(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.tableData = res.data.list;
          this.total = res.data.total;
        }
      });
    },
    // 更新套餐
    goUpdatePackage(packageId, packageStatus) {
      let params = {
        packageId: packageId,
        packageStatus: packageStatus,
      };
      goUpdatePackage(params).then((res) => {
        if (res.isSuccess == "yes") {
          this.$message({
            type: "success",
            message: "操作成功!",
          });
          this.getPackageList();
        }
      });
    },
    // 新增套餐
    goAddPackage() {
      let params = {
        merchantId: this.tableParams.merchantId,
        shopId: this.tableParams.shopId,
        packageName: this.params.packageName,
        packageAmount: this.params.rechargeMoney,
        giveAmount: this.params.giftMoney,
        remarks: this.params.remarks,
      };
      goAddPackage(params).then((res) => {
        if (res.isSuccess == "yes") {
          this.$message({
            type: "success",
            message: "操作成功!",
          });
          this.addPackageShow = false;
          this.getPackageList();
        }
      });
    },
    // 【监听】表格重载
    reload() {
      this.tableParams.page = 1;
      this.currentPage = 1;
      this.getPackageList();
    },
    // 【监听】表格模糊查询
    kwChange: _.debounce(function () {
      this.reload();
    }, 500),
    // 【监听】筛选商户
    onMerchantSel() {
      this.tableParams.shopId = "";
      this.tableParams.shopName = "";
      this.tableShopList = [];
      this.tableData = [];
      this.total = 0;
      this.tableParams.page = 1;
      this.tableParams.pageSize = 10;
      if (this.tableParams.merchantId) {
        this.commercialList.forEach((item) => {
          if (this.tableParams.merchantId == item.merchantId) {
            this.tableParams.merchantName = item.merchantName;
          }
        });
        this.getTableIdShopList();
      }
    },
    // 【监听】筛选店铺
    onShopSel() {
      if (this.tableParams.shopId) {
        this.tableShopList.forEach((item) => {
          if (this.tableParams.shopId == item.shopId) {
            this.tableParams.shopName = item.shopName;
          }
        });
        this.getPackageList();
      } else {
        this.total = 0;
        this.currentPage = 1;
        this.tableData = [];
      }
    },
    // 【请求】商户下拉列表
    getAutoCommercialList() {
      getAutoCommercialList().then((res) => {
        if (res.isSuccess == "yes") {
          this.commercialList = res.data;
          if (res.data && res.data.length > 0) {
            this.tableParams.merchantId = res.data[0].merchantId;
            this.tableParams.merchantName = res.data[0].merchantName;
            this.getTableIdShopList();
          }
        }
      });
    },
    // 【请求】筛选商户店铺列表
    getTableIdShopList() {
      let data = {
        merchantId: this.tableParams.merchantId,
      };
      getIdShopList(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.tableShopList = res.data;
          if (res.data.length > 0) {
            this.tableParams.shopId = res.data[0].shopId;
            this.tableParams.shopName = res.data[0].shopName;
            this.getPackageList();
          }
        }
      });
    },
    // 【监听】表单提交
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.goAddPackage();
        } else {
          return false;
        }
      });
    },
    // 点击
    goClick(type, row) {
      switch (type) {
        case 1:
          if (this.tableParams.merchantId == "") {
            this.$message({
              type: "error",
              message: "请先选择商户!",
            });
          } else if (this.tableParams.shopId == "") {
            this.$message({
              type: "error",
              message: "请先选择门店!",
            });
          } else {
            this.params = {
              packageName: "",
              rechargeMoney: "",
              giftMoney: "",
              remarks: "",
            };
            this.$nextTick(() => {
              this.$refs["form-ref"].clearValidate();
            });
            this.addPackageShow = true;
          }
          break;
        case 2:
          this.$confirm("请确认是否上架?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }).then(() => {
            this.goUpdatePackage(row.packageId, 1);
          });
          break;
        default:
          this.$confirm("请确认是否下架?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }).then(() => {
            this.goUpdatePackage(row.packageId, 0);
          });
          break;
      }
    },
    // 【监听】表格分页点击
    onCurrentPage(page) {
      this.tableParams.page = page;
      this.getPackageList();
    },

    // 【监听】表格条数点击
    onSizeChange(size) {
      this.tableParams.pageSize = size;
      this.reload();
    },
  },
};
</script>
<style lang="scss" scoped>
#packageSetting {
  padding: 0.15rem;
  box-sizing: border-box;
  .table-search {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.15rem;
    .search-L {
      display: flex;
    }
  }
  .table-footer {
    margin-top: 0.1rem;
    display: flex;
    justify-content: flex-end;
  }
  .tips {
    margin-top: 10px;
    font-size: 14px;
    color: red;
  }
}
</style>